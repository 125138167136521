<template>
  <v-layout style="width: 100%">
    <SubviewCard
      :style="{
        backgroundColor: pregnancyIndex % 2 == 0 ? 'white' : '#F9F9F9',
        width: '100%',
      }"
      class="mx-6"
      noActions
      :padding="false"
    >
      <v-layout column>
        <v-layout
          :class="['full-width']"
          v-for="(child, index) in value.childrens"
          :key="index"
          column
        >
          <v-layout justify-start class="mt-3 mb-4 mx-6" v-if="index == 0">
            <DefaultSubtitle color="labels">{{ `Ciąża ${pregnancyIndex}` }}</DefaultSubtitle>
          </v-layout>
          <v-divider class="mb-3" v-if="child.kind != 0"></v-divider>
          <v-layout
            justify-center
            align-center
            :class="$vuetify.breakpoint.smAndDown && 'column'"
            class="mx-6"
          >
            <DefaultSubtitle
              v-if="child.kind != 0"
              :class="$vuetify.breakpoint.smAndDown && 'mb-3'"
              >{{ `Dziecko ${index + 1}` }}</DefaultSubtitle
            >
            <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
            <PregnancyStatusMenu
              v-if="!disabled && child.kind != 0"
              label="Zmień typ zakończenia"
              @select="(kind) => onChangeKind(kind, index)"
              :rightMargin="'0px'"
              small
            ></PregnancyStatusMenu>
          </v-layout>
          <v-layout
            align-center
            :class="$vuetify.breakpoint.smAndDown && 'column'"
          >
            <v-layout column class="pl-6 pr-4 py-3" style="width: 100%">
              <component
                :is="pregnancyComponent(child.kind)"
                :value.sync="value.childrens[index]"
                :disabled="disabled"
              >
              </component>
            </v-layout>
            <DefaultButton
              v-if="!disabled"
              @click.stop="(index) => onDelete(index)"
              bgColor="error"
              :class="[!$vuetify.breakpoint.smAndDown && 'mr-6']"
              withIcon
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </DefaultButton>
          </v-layout>
          <v-layout justify-start class="mb-3 mx-4" v-if="!disabled">
            <PregnancyStatusMenu
              v-if="child.kind == 0"
              label="Zakończ ciąże"
              @select="(kind) => onChangeKind(kind, index)"
              :rightMargin="'8px'"
            ></PregnancyStatusMenu>
            <PregnancyStatusMenu
              v-else-if="index === value.childrens.length - 1"
              label="Dodaj dziecko"
              @select="onAddChild"
              :rightMargin="'8px'"
              isChild
            ></PregnancyStatusMenu>
          </v-layout>
          <v-layout class="mb-3" v-else>
          </v-layout>
        </v-layout>
      </v-layout>
    </SubviewCard>
  </v-layout>
</template>

<script>
const IN_PROGRESS = 0;
const CARRIED = 1;
const UNCARRIED = 2;
const TERMINATED = 3;

export default {
  props: {
    value: {},
    pregnancyIndex: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    PregnancyStatusMenu: () =>
      import("@/components/menus/patientcard/PregnancyStatusMenu"),
    PregnancyInProgressForm: () =>
      import("@/components/forms/patientcards/PregnancyInProgressForm"),
    PregnancyCarriedForm: () =>
      import("@/components/forms/patientcards/PregnancyCarriedForm"),
    PregnancyUncarriedForm: () =>
      import("@/components/forms/patientcards/PregnancyUncarriedForm"),
    PregnancyTerminatedForm: () =>
      import("@/components/forms/patientcards/PregnancyTerminatedForm"),
  },
  methods: {
    pregnancyComponent(kind) {
      switch (kind) {
        case CARRIED:
          return "PregnancyCarriedForm";
        case UNCARRIED:
          return "PregnancyUncarriedForm";
        case TERMINATED:
          return "PregnancyTerminatedForm";
        case IN_PROGRESS:
        default:
          return "PregnancyInProgressForm";
      }
    },
    onChangeKind(kind, index) {
      this.$set(this.value.childrens[index], "kind", kind);
    },
    onAddChild(kind) {
      const childrends = this.value.childrens;
      this.value.childrens = [
        ...childrends,
        {
          pregnancy_type: 0,
          date: "",
          week: "",
          mass: "",
          length: "",
          kind: kind,
          reason: "",
        },
      ];
    },
    onDelete(index) {
      if (this.value.childrens.length > 1) {
        this.value.childrens.splice(index, 1);
      } else {
        this.$emit("delete");
      }
    },
  },
};
</script>

<style scoped>
.btn-placeholder {
  width: 196px;
  height: 36px;
}
</style>